import { FormEvent, useState } from "react"
import styled from "styled-components"

import { HomeGroupsDropdown } from "src/components/Dropdown/HomeGroupsDropdown"
import { HomesDropdown } from "src/components/Dropdown/HomesDropdown"
import { HomeGroupRolePicker } from "src/components/Organizations/Organization/HomeGroupRolePicker"
import { HomeRolePicker } from "src/components/Organizations/Organization/HomeRolePicker"
import { MemberAccessPicker } from "src/components/Organizations/Organization/MemberAccessPicker"
import { OrganizationRolePicker } from "src/components/Organizations/Organization/OrganizationRolePicker"
import { HREF_MINUT_ORGANIZATION_PERMISSIONS } from "src/constants/hrefs"
import { usePostHomeGroupMemberInvite } from "src/data/homeGroups/queries/homeGroupMemberQueries"
import {
  HomeGroupRole,
  TMaybeHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { TMaybeHome } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import {
  usePostHomeMemberInvite,
  usePostMemberInvite,
} from "src/data/organizations/queries/inviteQueries"
import {
  HomeRole,
  TOrganizationRole,
} from "src/data/organizations/types/organizationMemberTypes"
import { useToast } from "src/hooks/useToast"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { MButton } from "src/ui/Button/MButton"
import { TextButton } from "src/ui/Button/TextButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { FullscreenActionBar } from "src/ui/Modals/FullscreenActionBar"
import { FullscreenView } from "src/ui/Modals/FullscreenView"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"
import { ToastActionButton } from "src/ui/Toast/Toast"

import { TMemberAccess } from "./memberAccessTypes"

export function AddMemberAccess({
  initialAccessTarget,
  homeGroupId,
  homeId,
}: {
  initialAccessTarget: TMemberAccess
  homeGroupId?: string
  homeId?: string
}) {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const { navigate, goBack } = useRouter()

  const [email, setEmail] = useState("")
  const [orgRole, setOrgRole] = useState<TOrganizationRole>()
  const [hgRole, setHGRole] = useState<HomeGroupRole>()
  const [homeRole, setHomeRole] = useState<HomeRole>()
  const [access, setAccess] = useState<TMemberAccess>(initialAccessTarget)
  const [selectedHome, setSelectedHome] = useState<TMaybeHome>(null)
  const [selectedHomegroup, setSelectedHomegroup] =
    useState<TMaybeHomeGroup>(null)
  const postMemberInvite = usePostMemberInvite()
  const postHomeGroupMemberInvite = usePostHomeGroupMemberInvite()
  const postHomeMemberInvite = usePostHomeMemberInvite()
  const [alert, setAlert] = useState<{
    text: string
    type?: "error"
  }>({ text: "" })

  const valid = !!email && (orgRole || hgRole || homeRole)

  const toast = useToast({
    action: (
      <ToastActionButton
        text={t(langKeys.organizations_invitation_view_action)}
        onClick={() => navigate(Routes.Organization.location())}
      />
    ),
  })

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    if (!valid) {
      return
    }

    const options = {
      onSuccess: (r: { email?: string }) => {
        toast.show(
          <span>
            {t(langKeys.organizations_invitation_email_sent_notification, {
              email: r.email,
            })}
          </span>
        )
        setEmail("")
        goBack({ defaultPath: Routes.Organization.location() })
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
      onError: (e: any, data: { body: { email?: string } }) => {
        switch (e.response?.status) {
          case 409:
            setAlert({
              text: t(langKeys.organizations_invitation_active, {
                email: data.body.email,
              }),
              type: "error",
            })
            return
          default:
            setAlert({
              text: t(langKeys.failed_general_error_title),
              type: "error",
            })
        }
      },
    }

    switch (access) {
      case "org-level-access": {
        if (!orgRole) {
          return setAlert({
            text: t(langKeys.failed_general_error_title),
            type: "error",
          })
        }

        return postMemberInvite.mutate(
          { orgId, body: { email: email.trim(), role: orgRole } },
          options
        )
      }

      case "homegroup-level-access": {
        if (!hgRole) {
          return setAlert({
            text: t(langKeys.failed_general_error_title),
            type: "error",
          })
        }

        return postHomeGroupMemberInvite.mutate(
          {
            orgId,
            homeGroupId: selectedHomegroup?.id || "",
            body: { email: email.trim(), role: hgRole },
          },
          options
        )
      }

      case "home-level-access": {
        if (!homeRole) {
          return setAlert({
            text: t(langKeys.failed_general_error_title),
            type: "error",
          })
        }

        return postHomeMemberInvite.mutate(
          {
            orgId,
            homeId: selectedHome?.home_id || "",
            body: { email: email.trim(), role: homeRole },
          },
          options
        )
      }
    }
  }

  function handleCancel() {
    goBack({ defaultPath: Routes.Organization.location() })
  }

  return (
    <FullscreenView
      title={t(langKeys.organizations_add_member)}
      maxWidth="small"
      onClose={handleCancel}
    >
      <Box>
        <MemberContents>
          <div>
            <p>
              {t(langKeys.organizations_add_member_description)}{" "}
              <ExternalLink href={HREF_MINUT_ORGANIZATION_PERMISSIONS}>
                {t(langKeys.organizations_permissions_read_more_action)}
              </ExternalLink>
            </p>
          </div>

          <StyledForm onSubmit={(e) => handleSubmit(e)}>
            <MTextField
              label={t(langKeys.email)}
              type="email"
              value={email}
              onChange={(value) => setEmail(value)}
              required
            />
            <MemberAccessPicker access={access} onChange={setAccess} />

            {access === "homegroup-level-access" && (
              <HomeGroupsDropdown
                value={selectedHomegroup}
                onSelect={setSelectedHomegroup}
                required={access === "homegroup-level-access"}
                initialHomeGroupId={homeGroupId}
              />
            )}

            {access === "home-level-access" && (
              <HomesDropdown
                value={selectedHome}
                onSelect={setSelectedHome}
                required={access === "home-level-access"}
                initialHomeId={homeId}
              />
            )}

            <AccessRoleSwitcher
              access={access}
              orgRole={orgRole}
              hgRole={hgRole}
              homeRole={homeRole}
              setOrgRole={setOrgRole}
              setHGRole={setHGRole}
              setHomeRole={setHomeRole}
            />
            {alert.text && <MBanner type={alert.type}>{alert.text}</MBanner>}
            <FullscreenActionBar>
              <TextButton type="button" onClick={handleCancel}>
                {t(langKeys.cancel)}
              </TextButton>
              <MButton
                disabled={!valid}
                loading={postMemberInvite.isLoading}
                type="submit"
              >
                {t(langKeys.organizations_send_invite)}
              </MButton>
            </FullscreenActionBar>
          </StyledForm>
        </MemberContents>
      </Box>
    </FullscreenView>
  )
}

function AccessRoleSwitcher({
  access,
  orgRole,
  hgRole,
  homeRole,
  setOrgRole,
  setHGRole,
  setHomeRole,
}: {
  access: TMemberAccess
  orgRole: TOrganizationRole | undefined
  hgRole: HomeGroupRole | undefined
  homeRole: HomeRole | undefined
  setOrgRole: (role: TOrganizationRole) => void
  setHGRole: (role: HomeGroupRole) => void
  setHomeRole: (role: HomeRole) => void
}) {
  switch (access) {
    case "org-level-access":
      return (
        <OrganizationRolePicker
          value={orgRole}
          onChange={setOrgRole}
          roleFilter={(r) => r !== "minimum"}
        />
      )
    case "homegroup-level-access":
      return <HomeGroupRolePicker value={hgRole} onChange={setHGRole} />
    case "home-level-access":
      return <HomeRolePicker value={homeRole} onChange={setHomeRole} />
    default:
      return null
  }
}

const MemberContents = styled.div`
  display: grid;
  grid-gap: ${spacing.M};
`

const StyledForm = styled.form`
  display: grid;
  grid-gap: ${spacing.M};
`

const Box = styled.div`
  display: grid;
`
